
<template>
  <lkps-table
    :api-endpoint="apiEndpoint"
    :headers="headers"
    :form="form"
    single-export="Dapat Ditinjau"
  />
</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_mskt',
      headers: [
        [{
          label: 'Tahun Masuk',
          dataKey: 'tahun_masuk',
          order: 1,
          attributes: { rowspan: 2 },
        }, {
          label: 'Jumlah Mahasiswa Diterima',
          dataKey: 'jumlah_mahasiswa_diterima',
          order: 2,
          attributes: { rowspan: 2 },
          dataAttributes: { class: 'text-center' }
        }, { 
          label: 'Jumlah Mahasiswa yang Lulus Pada ...',
          attributes: { colspan: 7 },
        }, {
          label: 'Jumlah Lulusan sd Akhir TS',
          dataKey: 'jumlah_lulusan_sd_akhir_ts',
          order: 10,
          attributes: { rowspan: 2 },
          dataAttributes: { class: 'text-center' }
        }, {
          label: 'Rata-Rata Masa Studi',
          dataKey: 'rata_rata_masa_studi',
          order: 11,
          attributes: { rowspan: 2 },
          dataAttributes: { class: 'text-center' }
        }],
        [{
          label: 'Akhir TS-6',
          dataKey: 'akhir_ts_6',
          order: 3,
          dataAttributes: { class: 'text-center' },
        }, {
          label: 'Akhir TS-5',
          dataKey: 'akhir_ts_5',
          order: 4,
          dataAttributes: { class: 'text-center' },
        }, {
          label: 'Akhir TS-4',
          dataKey: 'akhir_ts_4',
          order: 5,
          dataAttributes: { class: 'text-center' },
        }, {
          label: 'Akhir TS-3',
          dataKey: 'akhir_ts_3',
          order: 6,
          dataAttributes: { class: 'text-center' },
        }, {
          label: 'Akhir TS-2',
          dataKey: 'akhir_ts_2',
          order: 7,
          dataAttributes: { class: 'text-center' },
        }, {
          label: 'Akhir TS-1',
          dataKey: 'akhir_ts_1',
          order: 8,
          dataAttributes: { class: 'text-center' },
        }, {
          label: 'Akhir TS',
          dataKey: 'akhir_ts',
          order: 9,
          dataAttributes: { class: 'text-center' },
        }]
      ],
      form: {
        inputs: [
        {
          label: 'Tahun Masuk',
          name: 'tahun_masuk',
          dataKey: 'tahun_masuk',
          type: 'textarea',
          placeholder: 'TS-1',
          rules: 'required',
        }, {
          label: 'Jumlah Mahasiswa Diterima',
          name: 'jumlah_mahasiswa_diterima',
          dataKey: 'jumlah_mahasiswa_diterima',
          type: 'number',
        }, {
          label: 'Akhir TS-6',
          name: 'akhir_ts_6',
          dataKey: 'akhir_ts_6',
          type: 'number',
          col: 4
        }, {
          label: 'Akhir TS-5',
          name: 'akhir_ts_5',
          dataKey: 'akhir_ts_5',
          type: 'number',
          col: 4
        }, {
          label: 'Akhir TS-4',
          name: 'akhir_ts_4',
          dataKey: 'akhir_ts_4',
          type: 'number',
          col: 4
        }, {
          label: 'Akhir TS-3',
          name: 'akhir_ts_3',
          dataKey: 'akhir_ts_3',
          type: 'number',
          col: 4
        }, {
          label: 'Akhir TS-2',
          name: 'akhir_ts_2',
          dataKey: 'akhir_ts_2',
          type: 'number',
          col: 4
        }, {
          label: 'Akhir TS-1',
          name: 'akhir_ts_1',
          dataKey: 'akhir_ts_1',
          type: 'number',
          col: 4
        }, {
          label: 'Akhir TS',
          name: 'akhir_ts',
          dataKey: 'akhir_ts',
          type: 'number',
        }, {
          label: 'Jumlah Lulusan SD Akhir TS',
          name: 'jumlah_lulusan_sd_akhir_ts',
          dataKey: 'jumlah_lulusan_sd_akhir_ts',
          type: 'number',
        }, {
          label: 'Rata-Rata Masa Studi',
          name: 'rata_rata_masa_studi',
          dataKey: 'rata_rata_masa_studi',
          type: 'number',
        }]
      },
    }
  },
}
</script>
